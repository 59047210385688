import SwiperSlider from 'integration-web-core--socle/js/assets/modules/_swiperSlider.class';
class HomeServices extends lm.Composant {
  constructor(id) {
    super(id);
  }
}
lm.DOMReady(function () {
  try {
    new HomeServices('homeservices');
  } catch (error) {
    throw new Error(`Error : ${error}`);
  }
  try {
    const sliderHome = document.querySelector('.m-slider-essentials');
    if (sliderHome) {
      new SwiperSlider('.m-slider-essentials');
    }
  } catch (error) {
    throw new Error(`Error : ${error}`);
  }
});
export default HomeServices;